<template>
  <Combobox
    :id="id"
    as="div"
    v-model="selected"
    @update:modelValue="updateCombobox"
    :multiple="multiple"
    :disabled="disabled"
    v-slot="{ open }"
    nullable
  >
    <Float
      composable
      as="div"
      strategy="absolute"
      class="relative"
      floating-as="template"
      :flip="50"
      :offset="6"
      enter="transition duration-200 ease-out"
      enter-from="scale-95 opacity-0"
      enter-to="scale-100 opacity-100"
      leave="transition duration-150 ease-in"
      leave-from="scale-100 opacity-100"
      leave-to="scale-95 opacity-0"
      transform
    >
      <FloatReference>
        <ComboboxButton
          class="flex w-full items-center pr-2"
          :class="[
            listboxWrapperClasses.all,
            minimal ? listboxWrapperClasses.minimal : listboxWrapperClasses.default,
            changed ? listboxWrapperClasses.changed: null,
            error ? listboxWrapperClasses.error: null,
            disabled ? 'cursor-not-allowed opacity-70': 'cursor-pointer',
            backgroundColor
          ]"
          v-slot="{ value }"
        >
        <!-- Button default leading icon -->
          <div v-if="icon" class="flex items-center ml-3 z-10 focus:outline-none">
            <Icon  :icon="icon" class="h-5 w-5" :class="[iconColor ? iconColor : 'text-charcoal-600 dark:text-charcoal-300', disabled ? 'cursor-not-allowed opacity-70' : null]" aria-hidden="true"/>
          </div>

          <ComboboxInput
            :class="[
              listboxInputClasses.all,
              minimal ? listboxInputClasses.minimal : listboxInputClasses.default,
              changed ? listboxInputClasses.changed : null,
              error ? listboxInputClasses.error : null,
              disabled ? 'cursor-not-allowed': 'cursor-pointer',
              backgroundColor
            ]"
            :displayValue="(value) =>  displayValue(value)"
            :placeholder="placeholder(value)"
            :readonly="!autocomplete"
            autocomplete="off"
            @change="query = $event.target.value"
            @focus="autocomplete ? $event.target.select() : null"
            @wheel.prevent
            @mouseover="hasMouseover = true"
            @mouseout="hasMouseover = false"
            :content="selected ? selected.label : null"
            v-tippy="{ onShow: (event) => showTippyOnLongButtonText(event, selected) }"
          />
          <!-- Clear Input Icon -->
          <Icon
            v-if="!disabled && (nullable && selected) && !isFiltering"
            @click.stop="clearComboBox"
            icon="heroicons:x-mark"
            class="h-5 w-5 text-charcoal-800 dark:text-charcoal-200"
            :class="[disabled ? 'cursor-not-allowed opacity-70': 'cursor-pointer']"
            aria-hidden="true"
          />
          <!-- Filter/Loading Icon -->
          <Icon
            v-if="(isFiltering && query.length) || loading"
            icon="heroicons:arrow-path"
            class="h-5 w-5 text-charcoal-800 dark:text-charcoal-200 animate-spin"
            aria-hidden="true"
          />
          <!-- Chevron Icon -->
          <Icon v-else
            :icon="open ? 'heroicons:chevron-up' : 'heroicons:chevron-down'"
            class="h-5 w-5 text-charcoal-800 dark:text-charcoal-200"
            :class="[disabled ? 'cursor-not-allowed opacity-70': 'cursor-pointer']"
            aria-hidden="true"
          />
        </ComboboxButton>
      </FloatReference>

      <TransitionRoot @after-leave="sortSelectedToTopWhenClosed()">
        <FloatContent>
          <ComboboxOptions
            class="max-h-60 w-full overflow-auto rounded bg-white border border-charcoal-300 dark:border-charcoal-700 py-1 text-sm shadow-lg focus:outline-none"
            :class="[contrast ? 'dark:bg-charcoal': 'dark:bg-charcoal-900']"
          >
            <div
              v-if="dynamicOptions.length === 0"
              class="relative cursor-default select-none px-4 py-2 text-charcoal-800  dark:text-charcoal-200"
            >
              No results found.
            </div>
            <ComboboxOption
              as="template"
              v-for="option in dynamicOptions"
              :key="option.value"
              :value="option"
              v-slot="{ selected, active }"
            >
              <li
                class="flex items-center cursor-pointer select-none p-2"
                :class="{
                  'bg-iris-600 text-white': active,
                  'text-charcoal-800 dark:text-charcoal-200': !active
                }"
              >
                <div
                  class="flex w-full gap-0.5 overflow-hidden"
                  :class="[!selected ? 'pr-[18px]' : null]"
                >
                  <div class="flex flex-col flex-1 truncate" :content="formatOptionTippy(option)" v-tippy="{onShow: showTippyOnLongOptionText}">
                    <span class="truncate">
                      {{ option?.label }}
                    </span>
                    <span
                      :class="['text-xs truncate', active ? 'text-indigo-100' : 'text-charcoal-500']"
                    >
                      {{ option?.secondaryLabel }}
                    </span>
                  </div>
                  <!-- Trailing Icon -->
                  <span v-if="option.trailingIcon?.icon"
                    :class="[
                      'self-center', active ? 'text-white' : (option.trailingIcon.color || 'text-green-700 dark:text-green-600')
                    ]">
                    <Icon :icon="option.trailingIcon.icon" class="w-5 h-5" aria-hidden="true" :content="option.trailingIcon.tippy" v-tippy="{ onShow: () => option.trailingIcon.tippy ? true : false }"/>
                  </span>
                </div>
                <!-- If selected, show check mark -->
                <Icon
                  v-if="selected"
                  icon="heroicons:check"
                  class="w-5 h-5"
                  :class="active ? 'text-white' : 'text-iris-700 dark:text-iris-300'"
                />
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </FloatContent>
      </TransitionRoot>
    </Float>

    <slot />
  </Combobox>
</template>

<script setup>
import { ref, computed, provide, watch } from 'vue'
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption, TransitionRoot } from '@headlessui/vue'
import { Icon } from '@iconify/vue'
import { debounce, uniqBy, sortBy } from 'lodash'
import { assert, normalizeText } from '@/js/utils.js'
import { Float, FloatContent, FloatReference } from '@headlessui-float/vue'

const props = defineProps({
  autocomplete: {
    type: [Boolean, Function]
  },
  changed: {
    type: Boolean,
  },
  contrast: {
    type: Boolean,
  },
  debounce: {
    type: Number,
    default: 0
  },
  disabled: {
    type: Boolean
  },
  error: {
    type: Boolean
  },
  icon: {
    type: String
  },
  iconColor: {
    type: String
  },
  id: {
    type: String,
    required: true
  },
  loading: {
    type: Boolean
  },
  minimal: {
    type: Boolean,
  },
  modelValue: {
    required: true
  },
  multiple: {
    type: Boolean
  },
  nullable: {
    type: Boolean
  },
  placeholder: {
    type: String,
  }
})

const emit = defineEmits(['update:modelValue', 'changed'])

const options = ref([])
const filteredOptions = ref(options.value)
let filterFunction = async (options, query) => options
const hasMouseover = ref(false) // Used to not show button tippy once longer option is clicked
const isFiltering = ref(false)
const selected = ref(props.multiple ? [] : null)
const query = ref('')
const results = ref(options.value)

/**
 * Provide <Option/> components when an API to register and unregister
 * themselves as options when they are mounted/unmounted.
*/
provide('select', {
  register(option) {
    options.value.push(option)
  },

  unregister(value) {
    options.value = options.value.filter(option => option.value !== value)
  }
})

function updateCombobox() {
  // Backspacing the Autocomplete and tabing will null out the selected value and update the modelValue
  if (selected.value === null) {
    emit('update:modelValue', null)
    emit('changed', null)
    // When selected.value is null, reset autocomplete results.value back to default (options.value)
    results.value = options.value
  } else {
    emit('update:modelValue', props.multiple ? selected.value.map(select => select.value) : selected.value.value)
    emit('changed', props.multiple ? selected.value.map(select => select.value) : selected.value.value)
  }
}

function clearComboBox() {
  emit('update:modelValue', null)
  query.value = ''
  results.value = options.value
  selected.value = null
}

/**
 * Any time the options change, update our filtered options to match; including
 * currently selected values in the options list.
 */
watch(options, (value) => {
  if (props.multiple) {
    filteredOptions.value = uniqBy([...value], 'value').filter(Boolean)
  } else {
    filteredOptions.value = uniqBy([selected.value, ...value], 'value').filter(Boolean)
  }
}, { deep: true })


const dynamicOptions = computed(() => {
  let options = filteredOptions.value.filter(option => {
    return normalizeText(option.label).toLowerCase().includes(query.value.toLowerCase()) || normalizeText(option.label).toLowerCase().includes(selected.value?.label.toLowerCase())
  })

  // If options value's are typeof Number, sort by the value
  if (options.length && typeof options[0].value === 'number') {
    return sortBy(options, ['value'])
  }
  // Else if it is a string, sort by however the options currently are
  return options
})

/**
 * Execute our filter function and update options every time the query changes.
*/
const executeFilter = debounce(async () => {
  try {
    if (query.value.length > 1) {
      results.value = await filterFunction(options.value, query.value)
    }
    else {
      results.value = options.value
    }
  } finally {
    isFiltering.value = false
  }

  assert(
    Array.isArray(results.value),
    `[Listbox Id = ${props.id}]: Custom filter function must return array of results.`
  )

  assert(
    results.value.every(result => result.label !== undefined && result.value !== undefined),
    `[Listbox Id = ${props.id}]: Custom filter function results must have a label and value property.`
  )

  /*
    This takes the current list of options, adds the currently selected to the top of the list
    and then uses the query to put those matching underneath
  */
  if (props.multiple) {
    filteredOptions.value = uniqBy([...results.value, ...options.value], 'value').filter(Boolean)
  } else {
    filteredOptions.value = [...uniqBy([selected.value, ...results.value], 'value').filter(Boolean)]
  }
}, props.debounce)

/*
  By default the execute function always sorts selected.value to top of the list. However, with the new structure
  of leaving search results in options, if a user selects another option in the list without executing a new search
  the selected wouldn't be at the top. This function will resort the options when closed with selected to top.
*/
function sortSelectedToTopWhenClosed() {
  query.value = ''

  if (props.autocomplete) {
    filteredOptions.value =  [...uniqBy([selected.value, ...results.value], 'value').filter(Boolean)]
  }
}

/*
  Anytime the selected.value is null, we need to reset the filteredOptions
  to the options value (default options list).
*/
watch(() => selected.value, (newValue) => {
  if (!newValue && props.autocomplete) {
    filteredOptions.value = options.value
  }
})

/**
 * Any time the search term or underlying options change, execute
 * the configured filter function.
 */
watch([query, options], async () => {
  /**
   * We need to wrap executeFilter() in an if (props.autocomplete) because whenever one Listbox options
   * relies on a parent Listbox, the options wouldn't change on the second because this function would
   * fire for non autocomplete Listbox's.
  */
  if (props.autocomplete) {
    executeFilter()

    if (query.value.length > 1) {
      isFiltering.value = true
    }
  }
}, { immediate: true })

/**
 * Update the internal filter function depending on what value
 * the user has provided for :autocomplete.
 */
watch(() => props.autocomplete, (value) => {
  // User has provided a custom filter function.
  if (typeof value === 'function') {
    filterFunction = props.autocomplete
  }
  // User wants a simple client-side autocomplete.
  else if (value === true) {
    filterFunction = async (options, query) => query === ''
      ? options
      : options.filter((option) =>
        option.label
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )
  }
  // Do no filtering, return options as-is.
  else {
    filterFunction = async (options, query) => options
  }
}, { immediate: true })

/**
 * Map external model value to the selected option { label, value } tuple.
 *
 * We need to also watch the modelValue as there is a issue with multiple watchers
 * and the selected.value catching the first selected.value before modelValue has
 * come back from update.
 */
watch([filteredOptions, () => props.modelValue], ([filteredOptions, modelValue]) => {
  if (props.multiple) {
    selected.value = filteredOptions.filter(option => props.modelValue.includes(option.value))
  } else {
    selected.value = filteredOptions.find(option => option.value === modelValue || (selected.value !== null && selected.value === modelValue)) || null
  }
}, {immediate: true})


// ================================================================
// ================== GENERAL LISTBOX FUNCTIONS ===================
// ================================================================
function displayValue(value) {
  if (!value) return

  return Array.isArray(value) && value.length ? `${value?.length} selected` : value.label
}

function placeholder(value) {
  if (!props.placeholder && (Array.isArray(value) && !value.length)) {
    return 'Select one or more'
  }

  return props.placeholder || 'Select one'
}

/*
  Shows Tippy if the Option Label or the Option Secondary Label are truncated
*/
const showTippyOnLongOptionText = (e) => {
  return (e.reference.children[0].offsetWidth < e.reference.children[0].scrollWidth) ||
  (e.reference.children[1].offsetWidth < e.reference.children[1].scrollWidth)
}

const showTippyOnLongButtonText = (e, selected) => {
  if (!selected || !hasMouseover.value) return false

  return !Array.isArray(selected.value) && e.reference.offsetWidth <  e.reference.scrollWidth
}

const formatOptionTippy = (option) => {
  if (option.secondaryLabel) {
    return `
    <div class="flex flex-col p-1 text-charcoal-200"">
      ${option.label}
      <span class="italic text-xs text-charcoal-300">${option.secondaryLabel}</span>
    </div>
  `
  }

  return `
  <div class="flex p-1">
    ${option.label}
  </div>
  `
}

// ================================================================
// ===================== BUTTON/INPUT STYLES ======================
// ================================================================
const listboxWrapperClasses = computed(() => ({
  all: `
    relative
    flex
    items-center
    w-full
    overflow-hidden
    text-left
    text-sm
    ${props.contrast ? 'dark:bg-charcoal' : 'dark:bg-charcoal-900'}
  `,

  changed: `
    border-yellow-500
    dark:border-yellow-400
  `,

  error: `
    border-red-500
    dark:border-red-500
  `,

  default: `
    shadow-sm
    rounded-md
    border
    border-charcoal-300
    dark:border-charcoal-700
  `,

  disabled: `
    cursor-not-allowed
    opacity-70
  `,

  minimal: `
    shadow-none
    border-b
    bg-transparent
    border-charcoal-300
    dark:border-charcoal-700
    dark:bg-transparent
  `
}))

const listboxInputClasses = computed(() => ({
  all: `
    flex-1
    truncate
    text-sm
    border-none
    py-2
    px-3
    leading-5
    text-charcoal-800
    dark:text-charcoal-200
    focus:ring-0
    focus:outline-none
    ${props.disabled ?
    'placeholder:text-charcoal-800 placeholder:dark:text-charcoal-200' :
    'placeholder:text-charcoal-700 placeholder:dark:text-charcoal-400'
  }
    ${props.contrast ? 'dark:bg-charcoal' : 'dark:bg-charcoal-900'}
  `,

  disabled: `
    cursor-not-allowed
    opacity-70
  `,

  minimal: `
    bg-transparent
    dark:bg-transparent
  `
}))

const backgroundColor = computed(() => {
  // Minimal Listbox Background Color
  if (props.minimal) {
    return 'bg-transparent dark:bg-transparent'
  }

  // Changed Listbox Background Color
  else if(props.changed) {
    return 'bg-yellow-50'
  }

  // Changed Listbox Background Color
  else if(props.error) {
    return 'bg-red-50'
  }

  // Default
  return 'bg-white'
})
</script>