import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
import axios from 'axios'
import state from './store/state.js'
import NProgress from 'nprogress'

NProgress.configure({
  showSpinner: false
})

const baseURL = import.meta.env.VITE_MODE === 'production' ? '/api/v1' : `https://${import.meta.env.VITE_HELPER_SERVER_NAME}/api/v1`

const checker = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

// TODO: These should likely be moved to views. They are nested views.
/*
  The following components are already configured to lazy load the new way () => import('......')
  But as Dustin's message says above. They need to be moved eventually to the views directory
  from the components directory

  2. OpportunitiesContainer.vue
*/

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/user/login',
      name: 'login',
      component: () => import('../views/LogIn.vue'),
      meta: {
        layout: 'Empty'
      }
    },
    {
      path: '/',
      name: 'dashboard',
      component: () => import('../views/Dashboard.vue'),
      meta: {
        title: 'Helper - Dashboard'
      }
    },

    {
      path: '/Playground',
      name: 'playground',
      component: () => import('../layouts/Experimental/Playground.vue'),
      meta: {
        title: 'Helper - UI Playground'
      },
      beforeEnter: (to, from, next) => {
        // reject the navigation if not in development mode and return to dashboard
        if (import.meta.env.MODE !== 'development') {
          next({ name: 'dashboard' })
          return false
        }
        next()
      }
    },
    {
      path: '/event-log',
      name: 'event-log',
      component: () => import('../views/EventLog.vue'),
      meta: {
        title: 'Helper - Event Log'
      }
    },
    {
      path: '/MySettings',
      component: () => import('../views/MySettings.vue'),
      children: [
        {
          path: 'MyAccounts',
          name: 'settings.my-accounts',
          component: () => import('../views/Account/MyAccountsSettings.vue'),
          meta: {
            title: 'Helper - My Settings - My Accounts'
          }
        },
        {
          path: 'MyProductAdoptions',
          name: 'settings.my-product-adoptions',
          component: () => import('../views/ProductAdoption/MyProductAdoptionSettings.vue'),
          meta: {
            title: 'Helper - My Settings - My Product Adoptions'
          }
        },
        {
          path: 'MyPOCs',
          name: 'settings.my-pocs',
          component: () => import('../views/POC/MyPOCSettings.vue'),
          meta: {
            title: 'Helper - My Settings - My POCs'
          }
        },
      ],
      meta: {
        title: 'Helper - My Settings'
      }
    },
    {
      path: '/Account',
      component: () => import('../views/Account/Account.vue'),
      children: [
        {
          path: '',
          name: 'accounts',
          component: () => import('../views/Account/MyAccounts.vue'),
          meta: {
            title: 'Helper - My Accounts'
          }
        },
        {
          path: 'Heatmap/Products',
          name: 'heatmap.products',
          component: () => import('../views/Account/AccountProductsHeatmap.vue'),
          meta: {
            title: 'Helper - Account - Heatmap - Products'
          }
        },
        {
          path: 'Heatmap/Workloads',
          name: 'heatmap.workloads',
          component: () => import('../views/Account/AccountWorkloadsHeatmap.vue'),
          meta: {
            title: 'Helper - Account - Heatmap - Workloads'
          }
        }
      ],
      meta: {
        auth: true
      }
    },
    {
      path: '/MyProductAdoptions',
      name: 'myProductAdoptions',
      component: () => import('../views/ProductAdoption/MyProductAdoptions.vue'),
      children: [
        {
          path: 'list',
          name: 'myProductAdoptions.list',
          component: () => import('../views/ProductAdoption/List.vue'),
          meta: {
            title: 'Helper - My Product Adoption - List'
          }
        },
        {
          path: 'board',
          name: 'myProductAdoptions.board',
          component: () => import('../views/ProductAdoption/BoardView.vue'),
          meta: {
            title: 'Helper - My Product Adoption - Board'
          }
        },
      ],
      meta: {
        auth: true,
        title: 'Helper - My Product Adoptions'
      }
    },
    {
      path: '/MyPOCs',
      name: 'myPOCs',
      component: () => import('../views/POC/MyPOCs.vue'),
      meta: {
        auth: true,
        title: 'Helper - My POCs'
      }
    },
    {
      path: '/ProductAdoption/:id',
      name: 'productAdoption',
      component: () => import('../views/ProductAdoption/ProductAdoption.vue'),
      children: [
        {
          path: 'timeline',
          alias: '',
          name: 'productAdoption.show',
          component: () => import('../views/ProductAdoption/Timeline.vue'),
          meta: {
            title: 'Helper - Product Adoption - Timeline'
          }
        },
        {
          path: 'metrics',
          name: 'productAdoption.metrics',
          component: () => import('../views/ProductAdoption/Metrics.vue'),
          meta: {
            title: 'Helper - Product Adoption - Metrics'
          }
        },
        {
          path: 'attachments',
          name: 'productAdoption.attachments',
          component: () => import('../views/Attachments.vue'),
          props: (route) => ({ productAdoptionId: route.params.id }),
          meta: {
            title: 'Helper - Product Adoption - Attachments'
          }
        },
        {
          path: 'opportunities',
          name: 'productAdoption.opportunities',
          component: () => import('../views/ProductAdoption/RelatedOpportunities.vue'),
          meta: {
            title: 'Helper - Product Adoption - Related Opportunities'
          }
        }
      ],
      meta: {
        auth: true,
        title: 'Helper - Product Adoption'
      }
    },

    {
      path: '/POC/:id',
      name: 'poc.pocDetails',
      component: () => import('../views/POC/POCDetails.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        auth: true,
        title: 'Helper - POC Details'
      }
    },
    {
      path: '/Contacts',
      name: 'contacts',
      component: () => import('../views/Contact/MyContacts.vue'),
      meta: {
        auth: true,
        title: 'Helper - My Contacts'
      }
    },
    {
      path: '/Contacts/Salesforce',
      name: 'contacts.show',
      beforeEnter: (to, from, next) => {
        window.open(`https://${import.meta.env.VITE_HELPER_SALESFORCE_HOST}/${to.params.id}`)
        next(false)
      }
    },
    {
      path: '/Opportunity',
      name: 'opportunities',
      component: () => import('../views/Opportunity/MyOpportunities.vue'),
      children: [
        {
          path: 'list',
          name: 'opportunities.list',
          component: () => import('../views/Opportunity/List.vue'),
          meta: {
            title: 'Helper - My Opportunities - List'
          }
        },
        {
          path: 'board',
          name: 'opportunities.board',
          component: () => import('../views/Opportunity/BoardView.vue'),
          meta: {
            title: 'Helper - My Opportunities - Board'
          }
        },
        {
          path: 'table',
          name: 'opportunities.table',
          component: () => import('../views/Opportunity/TableView.vue'),
          meta: {
            title: 'Helper - My Opportunities - Table'
          }
        },
      ],
      meta: {
        auth: true,
        title: 'Helper - My Opportunities'
      }
    },
    {
      path: '/Recents',
      name: 'recents',
      component: () => import('../views/Recents.vue'),
      meta: {
        auth: true,
        title: 'Helper - Recents'
      }
    },
    {
      path: '/Reports',
      name: 'reports',
      component: () => import('../views/Reports.vue'),
      meta: {
        auth: true,
        title: 'Helper - Reports'
      }
    },
    {
      path: '/reports/implementation-strategy-heatmap',
      name: 'reports.implementationstrategyheatmap',
      component: () => import('../views/ImplementationStrategyHeatmap.vue'),
      meta: {
        auth: true,
        title: 'Helper - Implementation Strategy Heatmap'
      }
    },
    {
      path: '/reports/implementation-plan-report',
      name: 'reports.implementationplanreport',
      component: () => import('../views/ImplementationPlanReport.vue'),
      meta: {
        auth: true,
        title: 'Helper - Implementation Plan Report'
      }
    },
    {
      path: '/Resources',
      name: 'resources',
      component: () => import('../views/Resources.vue'),
      meta: {
        auth: true,
        title: 'Helper - Resources'
      }
    },
    {
      path: '/Team',
      name: 'team',
      component: () => import('../views/Team.vue'),
      children: [
        {
          path: '',
          name: 'team.list',
          component: () => import('../views/Opportunity/List.vue'),
          meta: {
            title: 'Helper - Team Opportunities - List'
          }
        },
        {
          path: 'board',
          name: 'team.board',
          component: () => import('../views/Opportunity/BoardView.vue'),
          meta: {
            title: 'Helper - Team Opportunities - Board'
          }
        },
        {
          path: 'product-adoptions-list',
          name: 'team.product-adoptions.list',
          component: () => import('../views/ProductAdoption/List.vue'),
          meta: {
            title: 'Helper - Team Product Adoptions - List'
          }
        },
        {
          path: 'product-adoptions-board',
          name: 'team.product-adoptions.board',
          component: () => import('../views/ProductAdoption/BoardView.vue'),
          meta: {
            title: 'Helper - Team Product Adoptions - Board'
          }
        },
        {
          path: 'accounts',
          name: 'team.accounts',
          component: () => import('../views/Account/MyAccounts.vue'),
          meta: {
            title: 'Helper - Team - Accounts'
          }
        },
        {
          path: 'product-heatmap',
          name: 'team.product-heatmap',
          component: () => import('../views/Account/AccountProductsHeatmap.vue'),
          meta: {
            title: 'Helper - Team - Product Heatmap'
          }
        },
        {
          path: 'workload-heatmap',
          name: 'team.workload-heatmap',
          component: () => import('../views/Account/AccountWorkloadsHeatmap.vue'),
          meta: {
            title: 'Helper - Team - Workload Heatmap'
          }
        },
        {
          path: 'tasks',
          name: 'team.tasks',
          component: () => import('@/components/Task/List.vue'),
          meta: {
            title: 'Helper - Team - Tasks'
          }
        },
        {
          path: 'pocs',
          name: 'team.pocs',
          component: () => import('@/views/POC/Container.vue'),
          meta: {
            title: 'Helper - Team - POCs'
          }
        },
      ],
      meta: {
        auth: true,
        title: 'Helper - My Team'
      }
    },
    {
      path: '/Tasks',
      name: 'my.tasks',
      component: () => import('../views/Task/MyTasks.vue'),
      meta: {
        auth: true,
        title: 'Helper - My Tasks'
      }
    },
    {
      path: '/OpportunityDetails/:id',
      component: () => import('../views/Opportunity/Opportunity.vue'),
      children: [
        {
          path: '',
          name: 'opportunity.details',
          component: () => import('../views/Opportunity/Details.vue'),
          meta: {
            title: 'Helper - Opportunity - Details'
          }
        },
        {
          path: 'contacts',
          name: 'opportunity.contacts',
          component: () => import('../views/Contact/Contacts.vue'),
          meta: {
            title: 'Helper - Opportunity - Contacts'
          }
        },
        {
          path: 'SizerBoms',
          name: 'opportunity.sizer-boms',
          component: () => import('../views/Opportunity/SizerBoms.vue'),
          meta: {
            title: 'Helper - Opportunity - Sizer Boms'
          }
        },
        {
          path: 'attachments',
          name: 'opportunity.attachments',
          component: () => import('../views/Attachments.vue'),
          props: (route) => ({ opportunityId: route.params.id }),
          meta: {
            title: 'Helper - Opportunity - Attachments'
          }
        },
        {
          path: 'activities',
          name: 'opportunity.activities',
          component: () => import('../views/Opportunity/Activities.vue'),
          meta: {
            title: 'Helper - Opportunity - Activities'
          }
        },
        {
          path: 'cases',
          name: 'opportunity.cases',
          component: () => import('../views/Cases.vue'),
          meta: {
            title: 'Helper - Opportunity - SE Support Cases'
          }
        }
      ],
      meta: {
        auth: true
      }
    },
    {
      path: '/AccountDetails/:id',
      component: () => import('../views/Account/AccountDetails.vue'),
      children: [
        {
          path: '',
          name: 'account.opportunities',
          component: () => import('../views/Opportunity/Container.vue'),
          children: [
            {
              path: '',
              name: 'account.opportunities.list',
              component: () => import('../views/Opportunity/List.vue'),
              meta: {
                title: 'Helper - Account Opportunities - List'
              }
            }
          ],
          meta: {
            title: 'Helper - Account - Opportunities'
          }
        },
        {
          path: 'onegtm',
          name: 'account.onegtm',
          component: () => import('../views/Account/OneGTM.vue'),
          props: (route) => ({ accountId: route.params.id }),
          meta: {
            title: 'Helper - Account - OneGTM'
          }
        },
        {
          path: 'attachments',
          name: 'account.attachments',
          component: () => import('../views/Attachments.vue'),
          props: (route) => ({ accountId: route.params.id }),
          meta: {
            title: 'Helper - Account - Attachments'
          }
        },
        {
          path: 'clusters',
          name: 'account.clusters',
          component: () => import('../views/Account/Clusters.vue'),
          props: (route) => ({ accountId: route.params.id }),
          meta: {
            title: 'Helper - Account - Clusters'
          }
        },
        {
          path: 'contacts',
          name: 'account.contacts',
          component: () => import('../views/Contact/Contacts.vue'),
          meta: {
            title: 'Helper - Account - Contacts'
          }
        },
        {
          path: 'product-adoptions',
          name: 'account.product-adoptions',
          component: () => import('../views/ProductAdoption/Container.vue'),
          children: [
            {
              path: '',
              name: 'account.product-adoptions.list',
              component: () => import('../views/Account/ProductAdoptions.vue'),
              meta: {
                title: 'Helper - Account - Product Adoptions'
              }
            }
          ],
          meta: {
            title: 'Helper - Account - Product Adoptions'
          }
        },
        {
          path: 'tasks',
          name: 'account.tasks',
          component: () => import('../views/Task/Dashboard.vue'),
          meta: {
            title: 'Helper - Account - Tasks'
          }
        },
        {
          path: 'pocs',
          name: 'account.pocs',
          component: () => import('../views/POC/Container.vue'),
          meta: {
            title: 'Helper - Account - POCs'
          }
        },
        {
          path: 'workloads',
          name: 'account.workloads',
          component: () => import('../views/Account/Workloads.vue'),
          props: (route) => ({ accountId: route.params.id }),
          meta: {
            title: 'Helper - Account - Workloads'
          }
        },
        {
          path: 'competitive-intelligence',
          name: 'account.compIntelligence',
          component: () => import('../views/Account/CompetitiveIntelligence.vue'),
          props: (route) => ({ accountId: route.params.id }),
          meta: {
            title: 'Helper - Account - Competitive Intelligence'
          }
        },
        {
          path: 'checkin',
          name: 'account.checkin',
          component: () => import('../views/Account/Checkins.vue'),
          props: (route) => ({ accountId: route.params.id }),
          meta: {
            title: 'Helper - Account - Check-Ins'
          }
        },
        {
          path: 'cases',
          name: 'account.cases',
          component: () => import('../views/Cases.vue'),
          meta: {
            title: 'Helper - Account - SE Support Cases'
          }
        }
      ],
      meta: {
        auth: true
      }
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('../views/Notifications.vue'),
      meta: {
        auth: true,
        title: 'Helper - Notifications'
      }
    },

    // Admin-only Audit Settings
    {
      path: '/AuditOptions',
      name: 'audit.options',
      component: () => import('../views/Audit/Options.vue'),
      meta: {
        auth: true,
        title: 'Helper - Audit Options'
      }
    },

    // Audit ----------------------------------
    {
      path: '/Audit',
      component: () => import('../views/Audit/Audit.vue'),
      meta: {
        auth: true
      },
      children: [
        {
          path: 'MyPOCs',
          name: 'audit.my-pocs',
          component: () => import('../views/Audit/MyPOCs.vue'),
          meta: {
            auth: true,
            title: 'Helper - Audit - My POCs'
          }
        },
        {
          path: 'AuditDirectReports',
          name: 'audit.direct-reports',
          component: () => import('../views/Audit/DirectReports.vue'),
          meta: {
            auth: true
          }
        },
        {
          path: 'AuditMetrics',
          name: 'audit.metrics',
          component: () => import('../views/Audit/Metrics.vue'),
          meta: {
            auth: true,
            title: 'Helper - Audit - Metrics'
          }
        },
        {
          path: 'AuditMetrics/TeamView',
          name: 'audit.metrics.team-view',
          component: () => import('../views/Audit/MetricsTeamView.vue'),
          meta: {
            auth: true,
            title: 'Helper - Audit - Metrics - Team View'
          }
        },
        {
          path: 'AuditMetrics/POCView',
          name: 'audit.metrics.poc-view',
          component: () => import('../views/Audit/MetricsPOCView.vue'),
          props: true,
          meta: {
            auth: true,
            title: 'Helper - Audit - Metrics - POC View'
          }
        },
        {
          path: 'AuditAdmin',
          name: 'audit.admin',
          component: () => import('../views/Audit/Admin.vue'),
          meta: {
            auth: true,
            title: 'Helper - Audit - Admin'
          }
        },
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }

    return {
      x: 0,
      y: 0
    }
  }
})

//  HANDLEING SETTING TABS TITLES FOR ROUTING PURPOSES
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value or just set to Helper
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else {
    document.title = 'Helper'
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
  // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

router.beforeEach((toRoute, fromRoute, next) => {
  if (toRoute.matched.some(record => record.meta.auth)) {
    checker
      .get('/checkSession')
      .then(response => {
        state.userUUID = response.data.userUUID
        state.username = response.data.userName
        if (response.status === 200) {
          next()
        }
        else {
          next({ name: 'login' })
        }
      })
      .catch(() => {
        next({
          name: 'login',
          query: { redirect_uri: toRoute.fullPath }
        })
      })
  } else if (toRoute.fullPath === '/') {
    checker
      .get('/checkSession')
      .then(response => {
        if (response.status === 200) {
          next()
        } else {
          next({ name: 'login' })
        }
      })
      .catch(() => {
        next({ name: 'login' })
      })
  } else {
    next()
  }
})

// Page navigation progress bars.
router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})

trackRouter(router)

export default router
