<template>
  <div class="flex flex-col">
    <BuildInformation />

    <!-- Header -->
    <nav class="bg-iris-800 sm:z-50 sm:sticky sm:top-0">
      <div class="sm:px-4">
        <div class="flex items-center justify-between h-16">
          <div class="flex flex-1 items-center">
            <div class="hidden sm:flex flex-shrink-0 items-center mr-6">
              <router-link :to="{ name: 'dashboard' }" class="flex items-center"  @click="mobileNavIsOpen = false">
                <img class="h-4 mr-3" src="../assets/icons/Nutanix-Ident-X-white.png" alt="Nutanix logo" />
                <h1 class="font-attention font-medium text-lg text-white dark:text-charcoal-100">Helper</h1>
              </router-link>
            </div>
            <div class="flex-1">
              <Omnisearch />
            </div>

            <!-- Quick Links / Settings -->
            <div class="hidden md:block">
              <div class="ml-4 flex items-center md:ml-6">
                <!-- Help -->
                <div @click="launchIntercom()" content="Get Help" v-tippy class="p-1 border-2 border-transparent text-charcoal-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-iris-900" aria-label="Get Help">
                  <Icon icon="heroicons:question-mark-circle" class="w-6 h-6" />
                </div>

                <!-- Releases -->
                <div @click="showIntercomArticle()" v-tippy content="Releases" class="relative p-1 border-2 border-transparent text-charcoal-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-iris-900" aria-label="Releases">
                  <Icon icon="heroicons:rocket-launch" class="w-6 h-6" />
                  <!--
                    TODO: Uncomment once getting new release notes feature to show pulse
                  -->

                  <!-- <span class="absolute top-0 right-0 flex h-2.5 w-2.5" v-show="showNotification == true">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-2.5 w-2.5 bg-red-700"></span>
                  </span> -->
                </div>

                <!-- Notifications -->
                <router-link :to="{ name: 'notifications' }" v-tippy content="Notifications" class="relative p-1 border-2 border-transparent text-charcoal-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-iris-900" aria-label="Notifications">
                  <Icon icon="heroicons:bell" class="w-6 h-6" />
                  <span class="absolute top-0 right-0 flex h-2.5 w-2.5" v-show="hasUserNotifications">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-2.5 w-2.5 bg-red-600"></span>
                  </span>
                </router-link>

                <!-- Dark Mode Toggle -->
                <p @click="toggleDarkMode()" v-tippy :content="isDark ? 'Toggle Light Mode' : 'Toggle Dark Mode'" class="relative p-1 border-2 border-transparent text-charcoal-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-iris-900" aria-label="Toggle Dark Mode">
                  <Icon v-if="!isDark" icon="heroicons:sun" class="w-6 h-6" />
                  <Icon v-else icon="heroicons:moon" class="w-6 h-6" />
                </p>



                <!-- Profile dropdown -->
                <Dropdown class="ml-3">
                  <template #trigger>
                    <div>
                      <button class="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid" id="user-menu" aria-label="User menu" aria-haspopup="true">
                        <img class="h-8 w-8 rounded-full" :src="profilePhoto" alt="" />
                      </button>
                    </div>
                  </template>

                  <template #dropdown="{ toggle }">
                    <div class="w-auto rounded-md shadow-lg">
                      <div class="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5 divide-y divide-charcoal-100 dark:bg-charcoal-900 dark:divide-charcoal-700 dark:ring-charcoal-700" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                        <div class="px-4 py-3">
                          <p class="text-sm leading-5">
                            Signed in as
                          </p>
                          <p class="text-sm leading-5 font-semibold text-charcoal-800 dark:text-charcoal-200 truncate">
                            {{ user.email }}
                          </p>
                        </div>
                        <div class="py-1" @click="toggle">
                          <router-link :to="{ name: 'settings.my-accounts' }" class="block w-full text-left px-4 py-2 text-sm leading-5 text-charcoal-700 hover:bg-brand-purple hover:text-white focus:outline-none focus:bg-brand-purple focus:text-white dark:text-charcoal-300" role="menuitem">My Settings</router-link>
                        </div>
                        <div class="py-1">
                          <a :href="`https://nutanix.lightning.force.com/lightning/r/User/${user.id}/view`" target="__blank" class="block w-full text-left px-4 py-2 text-sm leading-5 text-charcoal-700 hover:bg-brand-purple hover:text-white focus:outline-none focus:bg-brand-purple focus:text-white dark:text-charcoal-300" role="menuitem">Update Salesforce Profile</a>
                        </div>
                        <div v-if="eventLogEnabled" class="py-1" @click="toggle">
                          <router-link :to="{ name: 'event-log' }" class="block w-full text-left px-4 py-2 text-sm leading-5 text-charcoal-700 hover:bg-brand-purple hover:text-white focus:outline-none focus:bg-brand-purple focus:text-white dark:text-charcoal-300" role="menuitem">Event Log</router-link>
                        </div>
                        <div class="py-1">
                          <a href="/logout" @click="logoutIntercom" class="block w-full text-left px-4 py-2 text-sm leading-5 text-charcoal-700 hover:bg-brand-purple hover:text-white focus:outline-none focus:bg-brand-purple focus:text-white dark:text-charcoal-300" role="menuitem">Sign out</a>
                        </div>
                      </div>
                    </div>
                  </template>
                </Dropdown>
              </div>
            </div>
            <!-- Mobile Navigation Toggle -->
            <div class="mr-2 ml-2 flex md:hidden">
              <button class="inline-flex items-center justify-center p-1.5 rounded-md text-charcoal-400 hover:text-white focus:outline-none focus:text-white" @click="mobileNavIsOpen = !mobileNavIsOpen">
                <Icon :icon="mobileNavIsOpen ? 'heroicons:x-mark' : 'heroicons:bars-3'" class="block h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
        <div>
          <div class="md:block">
            <div v-if="mobileNavIsOpen" class="ml-4 flex items-center justify-between h-10 md:ml-6">
              <!-- Help -->
              <div @click="launchIntercom()" content="Get Help" v-tippy class="p-1 border-2 border-transparent text-charcoal-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-iris-900" aria-label="Get Help">
                  <Icon icon="heroicons:question-mark-circle" class="w-6 h-6" />
              </div>

              <!-- Releases -->
              <div @click="showIntercomArticle()" v-tippy content="Releases" class="relative p-1 border-2 border-transparent text-charcoal-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-charcoal-700 dark:text-charcoal-400 dark:focus:bg-charcoal-700" aria-label="Releases">
                <Icon icon="heroicons:tag" class="w-6 h-6" />
                <!--
                  TODO: Uncomment once getting new release notes feature to show pulse
                 -->

                <!-- <span class="absolute top-0 right-0 flex h-2.5 w-2.5" v-show="showNotification == true">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-2.5 w-2.5 bg-red-700"></span>
                </span> -->
              </div>

              <!-- Notifications -->
              <router-link :to="{ name: 'notifications' }" v-tippy content="Notifications" class="relative p-1 border-2 border-transparent text-charcoal-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-charcoal-700 dark:text-charcoal-400 dark:focus:bg-charcoal-700" aria-label="Notifications">
                <Icon icon="heroicons:bell" class="w-6 h-6" />
                <span class="absolute top-0 right-0 flex h-2.5 w-2.5" v-show="hasUserNotifications">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-2.5 w-2.5 bg-red-600"></span>
                </span>
              </router-link>

              <!-- Dark Mode Toggle -->
              <p @click="toggleDarkMode()" v-tippy :content="isDark ? 'Toggle Light Mode' : 'Toggle Dark Mode'" class="relative p-1 border-2 border-transparent text-charcoal-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-charcoal-700 dark:text-charcoal-400 dark:focus:bg-charcoal-700" aria-label="Toggle Dark Mode">
                <Icon v-if="!isDark" icon="heroicons:sun" class="w-6 h-6" />
                <Icon v-else icon="heroicons:moon" class="w-6 h-6" />
              </p>

              <!-- Profile dropdown -->
              <Dropdown class="ml-3 mr-3">
                <template #trigger>
                  <div>
                    <button class="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid" id="user-menu" aria-label="User menu" aria-haspopup="true">
                      <img class="h-8 w-8 rounded-full" :src="profilePhoto" alt="" />
                    </button>
                  </div>
                </template>

                <template #dropdown="{ toggle }">
                  <div class="w-auto rounded-md shadow-lg">
                    <div class="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5 divide-y divide-charcoal-100 dark:bg-charcoal-900 dark:divide-charcoal-700 dark:ring-charcoal-700" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                      <div class="px-4 py-3">
                        <p class="text-sm leading-5">
                          Signed in as
                        </p>
                        <p class="text-sm leading-5 font-semibold text-charcoal-800 dark:text-charcoal-200 truncate">
                          {{ user.email }}
                        </p>
                      </div>
                      <div class="py-1" @click="toggle">
                        <router-link :to="{ name: 'settings.my-accounts' }" class="block w-full text-left px-4 py-2 text-sm leading-5 text-charcoal-700 hover:bg-brand-purple hover:text-white focus:outline-none focus:bg-brand-purple focus:text-white dark:text-charcoal-300" role="menuitem">My Settings</router-link>
                      </div>
                      <div class="py-1">
                        <a :href="`https://nutanix.lightning.force.com/lightning/r/User/${user.id}/view`" target="__blank" class="block w-full text-left px-4 py-2 text-sm leading-5 text-charcoal-700 hover:bg-brand-purple hover:text-white focus:outline-none focus:bg-brand-purple focus:text-white dark:text-charcoal-300" role="menuitem">Update Salesforce Profile</a>
                      </div>
                      <div v-if="eventLogEnabled" class="py-1" @click="toggle">
                        <router-link :to="{ name: 'event-log' }" class="block w-full text-left px-4 py-2 text-sm leading-5 text-charcoal-700 hover:bg-brand-purple hover:text-white focus:outline-none focus:bg-brand-purple focus:text-white dark:text-charcoal-300" role="menuitem">Event Log</router-link>
                      </div>
                      <div class="py-1">
                        <a href="/logout" @click="logoutIntercom" class="block w-full text-left px-4 py-2 text-sm leading-5 text-charcoal-700 hover:bg-brand-purple hover:text-white focus:outline-none focus:bg-brand-purple focus:text-white dark:text-charcoal-300" role="menuitem">Sign out</a>
                      </div>
                    </div>
                  </div>
                </template>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile Navigation -->
      <div v-if="mobileNavIsOpen">
        <div class="px-2 pt-2 pb-3 sm:px-3 space-y-2">

          <hr class="border-charcoal-600">

          <router-link exact :to="{ name: 'dashboard' }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              Dashboard
            </a>
          </router-link>

          <router-link :to="{ name: 'accounts' }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              My Accounts
            </a>
          </router-link>

          <router-link :to="{ name: `opportunities.${ defaultOpportunityRoute }` }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              My Opportunities
            </a>
          </router-link>

          <router-link :to="{ name: 'myPOCs' }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              My POCs
            </a>
          </router-link>

          <router-link v-if="showProductAdoption" :to="{ name: `myProductAdoptions.${ defaultProdAdoptionRoute }` }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              My Product Adoptions
            </a>
          </router-link>

          <router-link :to="{ name: 'my.tasks' }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              My Tasks
            </a>
          </router-link>

          <router-link :to="{ name: 'contacts' }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              My Contacts
            </a>
          </router-link>

          <router-link v-if="user && user.hasReports" :to="{ name: 'team.list' }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              My Team
            </a>
          </router-link>

          <hr class="mt-5 border-charcoal-600">

          <router-link :to="{ name: 'recents' }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              Recents
            </a>
          </router-link>

          <router-link :to="{ name: 'reports' }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              Reports
            </a>
          </router-link>

          <router-link :to="{ name: 'resources' }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              Resources
            </a>
          </router-link>

          <router-link v-if="audit_Active" :to="{ name: 'audit.my-pocs' }" class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white" @click="mobileNavIsOpen = false" v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="navigate" :class="isActive ? 'text-white' : 'text-charcoal-300 hover:text-white'">
              POC Audit
            </a>
          </router-link>

          <hr class="mt-5 border-charcoal-700">

        </div>
      </div>
    </nav>

    <div class="flex flex-1">
      <!-- FOR ICON MENU -->
      <nav class="no-scrollbar hidden md:block bg-charcoal-900 dark:bg-charcoal text-white dark:text-charcoal-200 border-r shadow-md border-charcoal-200 dark:border-charcoal-800 z-40 md:flex-col h-full md:fixed overflow-y-scroll overscroll-y-contain pb-20">
        <div class="py-2 transition-all duration-300 ease-in-out" :class="expandNavBar ? 'w-64' : 'w-12'">
          <button class="w-full mb-2 py-2 text-sm font-medium text-white dark:text-charcoal-200 hover:text-white hover:bg-iris-700 focus:outline-none" @click="toggleSidebar">
            <div v-if="expandNavBar"  class="flex">
              <Icon icon="heroicons:chevron-double-left" class="flex-shrink-0 h-6 w-6 ml-3 text-iris-200"/>
              <p class="self-center truncate ml-3">Collapse Sidebar</p>
            </div>
            <Icon v-else icon="heroicons:chevron-double-right" class="h-6 w-6 ml-3 text-iris-200" />
          </button>
           <hr class="mb-6 border-iris-600">
          <SecondaryNavigation :user="user" :expandMenu="expandNavBar" />
        </div>
      </nav>

      <!-- Main Content -->
      <div class="no-scrollbar flex-1 flex flex-col overflow-hidden">
        <div :class="`relative flex-1 flex flex-col px-4 py-4 sm:pr-4 sm:py-6 ${!expandNavBar ? 'md:ml-14' : 'md:ml-[265px]'}`">
          <slot />
          <!-- FEEDBACK  -->
          <div v-if="showFeedback" @click="showFeedbackContainer = true" class="fixed right-0 bottom-48 -rotate-90 origin-bottom-right flex items-center cursor-pointer group shadow-md dark:border dark:border-charcoal-400">
            <div class="bg-charcoal-200 rotate-90">
              <img src="/img/icons/favicon-32x32.png" alt="">
            </div>
            <div class="text-white bg-charcoal-800 group-hover:bg-charcoal-700 py-1 px-2">Feedback</div>
          </div>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="transition ease-out duration-300"
      enter-from-class="opacity-0 translate-x-3"
      enter-to-class="opacity-100 translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="opacity-100 translate-x-0"
      leave-to-class="opacity-0 translate-x-3">

      <div v-if="showFeedbackContainer" @click="dismiss" class="bg-transparent fixed inset-0 flex items-center justify-center z-50 p-14">
        <div @click.stop class="fixed right-10 bottom-16 w-screen max-w-xs">
          <Icon @click="dismiss" icon="heroicons:x-circle" class="bg-white rounded-full fixed right-[363px] -mt-1 h-9 w-9 text-charcoal-700 hover:text-charcoal-600" />
          <div class="rounded-lg drop-shadow-2xl ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div class="flex flex-col bg-white h-96">
              <p class="bg-brand-purple text-iris-300 text-lg font-semibold p-3 text-center">Help us improve Helper by completing a survey!</p>
              <a href="https://www.research.net/r/DRP2NGC" target="_blank" class="flex-1 m-4 relative group cursor-pointer" >
                <img src="/img/icons/HelperSurveyScreenshot.png" alt="" class="opacity-25 group-hover:opacity-60">
                <div class="absolute top-20 left-24">
                  <div class="relative border-2  w-28 h-16 border-charcoal-600">
                    <p class="absolute uppercase w-48  -left-8 top-3.5  py-1 bg-white tracking-widest font-semibold text-charcoal-800 group-hover:text-brand-purple">Take the survey</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeMount, provide } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useGtag } from 'vue-gtag-next'
import { Icon } from '@iconify/vue'
import BuildInformation from '@/components/Debug/BuildInformation.vue'
import SecondaryNavigation from '@/views/SecondaryNavigation.vue'
import Omnisearch from '@/components/Omnisearch.vue'
import Dropdown from '@/components/Core/Dropdown.vue'
import { v2 } from '@/services/api.js'
import * as Sentry from '@sentry/vue'
import snackbar from '@/js/snackbar.js'
import { getUnixTime } from 'date-fns'
import { useDark, useToggle } from '@vueuse/core'

const router = useRouter()
const store = useStore()
const { event } = useGtag()
const isDark = useDark()
const toggleDark = useToggle(isDark)

const audit_Active = computed(() => store.state.audit.audit_Active)
const mobileNavIsOpen = ref(false)
const user = ref({})
// const showNotification = ref(false)
const hasUserNotifications = ref(false)
const expandNavBar = ref(true)
const eventLogEnabled = ref(import.meta.env.VITE_EVENT_LOG === 'true')
const intercomEnabled = ref(import.meta.env.VITE_INTERCOM === 'true')
const showFeedbackContainer = ref(false)
const profilePhoto = ref(new URL('../assets/icons/default-user.png', import.meta.url).href)
const defaultProdAdoptionRoute = ref('list')
const defaultOpportunityRoute = ref('list')
const defaultAccountsRoute = ref('accounts')

// Providing the expandNavBar variable down to Opportunity.vue where we are using it to add padding to the save/close buttons
provide('expandNavBar', expandNavBar)

const showFeedback = computed(() => {
  return import.meta.env.VITE_FEEDBACK === 'true'
})

const showProductAdoption = computed(() => {
  return import.meta.env.VITE_PRODUCT_ADOPTION === 'true'
})

const launchIntercom = () => {
  window.Intercom('showSpace', 'home')
}

const showIntercomArticle = () => {
  window.Intercom('showArticle', '8417819')
}

const logoutIntercom = () => {
  window.Intercom('shutdown')
}

/*  COMMENTING OUT THIS METHOD UNTIL READY TO USE
updateNotificationStatus() {
  /~*
    * TODO:
    * Add configuration back in once new release notes to show pulse
    ~/
},*/

const showNotificationsSnackbar = () => {
  snackbar.show({
    dismissable: false,
    timeout: null,
    tone: 'info',
    heading: 'You Have New Notifications',
    message: 'Choose below to view My Notifications, or Snooze until your next login.',
    actions: [
      {
        label: 'View Notifications',
        button: {
          icon: null,
          iconStyle: null,
          color: 'blue'
        },
        click() {
          router.push({ path: '/notifications' })
          localStorage.setItem('snoozeNotifications', new Date().toString())
          snackbar.dismiss()
        }
      },
      {
        label: 'Snooze  ',
        button: {
          icon: null,
          iconStyle: null,
          color: 'gray'
        },
        click() {
          localStorage.setItem('snoozeNotifications', new Date().toString())
          snackbar.dismiss()
        }
      }
    ]
  })
}

const toggleSidebar = () => {
  expandNavBar.value = !expandNavBar.value
  // Set sidebar open closed for future
  localStorage.sidebarOpen = expandNavBar.value
}

const dismiss = () => {
  showFeedbackContainer.value = false
}

const toggleDarkMode = () => {
  toggleDark()

  store.state.appTheme = isDark.value ? 'dark' : 'light'
  window.dispatchEvent(new CustomEvent('helper-theme-changed', {
    detail: {
      theme: isDark.value ? 'dark' : 'light'
    }
  }))
}

const getDefaultProductAdoptionsRoute = async () => {
  let filterConfigTemp = (await v2.get('/me/filters', { params: { filterType: 'productAdoption' }})).data
  // Checks if the user has saved filters already, otherwise load the default filters
  if (filterConfigTemp.value.savedViews.length) {
    let temp = filterConfigTemp.value.savedViews.findIndex(view => view.defaultView === true )
    if (filterConfigTemp.value.savedViews[temp].viewLayout == 'list') {
      defaultProdAdoptionRoute.value = 'list'
    }
    else {
      defaultProdAdoptionRoute.value = 'board'
    }
  }
}

const getDefaultOpportunitiesRoute = async () => {
  let filterConfigTempOpportunity = (await v2.get('/me/filters', { params: { filterType: 'opportunity' }})).data
  if (filterConfigTempOpportunity.value.savedViews.length) {
    let temp = filterConfigTempOpportunity.value.savedViews.findIndex(view => view.defaultView === true )
    if (filterConfigTempOpportunity.value.savedViews[temp].viewLayout == 'list') {
      defaultOpportunityRoute.value = 'list'
    }
    else {
      defaultOpportunityRoute.value = 'board'
    }
  }
}

const getDefaultAccountsRoute = async () => {
  let filterConfigTempAccounts = (await v2.get('/me/filters', { params: { filterType: 'accounts' }})).data
  if (filterConfigTempAccounts.value.savedViews.length) {
    let temp = filterConfigTempAccounts.value.savedViews.findIndex(view => view.defaultView === true )
    if (filterConfigTempAccounts.value.savedViews[temp].viewLayout == 'accounts') {
      defaultAccountsRoute.value = 'accounts'
    }
    else {
      defaultAccountsRoute.value = `heatmap.${filterConfigTempAccounts.value.savedViews[temp].viewLayout}`
    }
  }
}

onBeforeMount(() => {
  v2.get('/me').then(response => {
    if (response.status == 200) {
      user.value = response.data
      store.state.sfUserData = response.data
      if (!user.value.photo.includes('/profilephoto/005/T')) {
        profilePhoto.value = user.value.photo
      }

      // Sentry User Context
      Sentry.setUser({ username: response.data.email })
    }
    // Setting User Properties to be tracked by Google Analytics
    event('user_properties', {
      user_region: user.value.region !== null ? user.value.region : 'Region Not Set',
      user_subregion: user.value.subregion !== null ? user.value.subregion : 'Subregion Not Set',
      user_theater: user.value.theater !== null ? user.value.theater : 'Theater Not Set'
    })
  })

  v2.get('/notifications?read=false').then(response => {
    if (response.status === 200) {
      hasUserNotifications.value = response.data.meta.total > 0
      if (hasUserNotifications.value && !localStorage.getItem('snoozeNotifications')) {
        showNotificationsSnackbar()
      }
    }
  })

  // Checked sidebar open/close status
  if (localStorage.sidebarOpen) {
    expandNavBar.value = JSON.parse(localStorage.sidebarOpen)
  }

  // Check for dark mode preference
  store.state.appTheme = isDark.value ? 'dark' : 'light'

  // Check for opportunity view preference
  if (localStorage.preferredOpportunityView) {
    store.state.preferredOpportunityView = localStorage.preferredOpportunityView
  }

  getDefaultProductAdoptionsRoute()
  getDefaultOpportunitiesRoute()
  getDefaultAccountsRoute()
})

watch(() => user.value, (user) => {
  if (intercomEnabled.value && user?.name && user?.email) {
    window.Intercom('boot', {
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      api_base: import.meta.env.VITE_INTERCOM_API_BASE,
      name: user.name,
      email: user.email,
      theater: user.theater,
      region: user.region,
      subregion: user.subregion,
      isManager: user.hasReports,
      sfdcDept: user.department,
      title: user.title,
      sfdcJobRole: user.jobRole,
      sfdcUsersProfile: user.usersProfile,
      sfdcUsersRole: user.usersRole,
      avatar: {
        'type': 'avatar',
        'image_url': user.photo
      },
      user_hash: user.hash,
      navMenuState: expandNavBar.value ? 'expanded' : 'collapsed', // Tracks whether the nav menu is expanded on login. Just used to see if we can derive any metrics on usage there.
      helper_last_access: getUnixTime(new Date()) //provide all dates as Unix Timestamp
    })
  }
}, {immediate: true})
</script>