<template>
  <Page padding="large" class="bg-gradient-to-tl from-1% from-iris-900 to-slate-700 to-80%">

    <Box v-slot="{ breakpoint }">
      <Box full marginBottom="medium" gapY="small" :shouldCollapse="breakpoint === 'xs'">
        <!-- Core Component Selector -->
        <Box shouldCollapse class="sm:w-[14rem]">
          <p class="text-sm text-center text-slate-100 dark:text-slate-200">Resuable Components</p>

          <!-- Cascade Select attempt -->
          <CascadeSelect2
            v-model="currentPlayground"
            :options="playgrounds"
            optionLabel="label"
            optionValue="value"
            optionGroupLabel="name"
            :optionGroupChildren="['components']"
            style="min-width: 14rem"
            placeholder="Select a playground"
          />
        </Box>

        <p class="flex-1 self-center text-center text-white text-2xl md:text-3xl order-first sm:order-none">Helper UI Playground</p>

        <!-- Toggle Light / Dark mode switch -->
        <Box justifyContent="center" sm:justifyContent="end">
          <Switch @click="toggleDark()" v-model="isDark" :class="[isDark ? 'bg-slate-700 border-slate-600' : 'bg-blue-500 border-blue-400', 'relative inline-flex self-center h-7 w-14 flex-shrink-0 cursor-pointer rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0']">
            <span class="sr-only">Use setting</span>
            <span :class="[isDark ? 'translate-x-7' : 'translate-x-0', 'pointer-events-none relative inline-block h-6 w-6 transform rounded-full bg-yellow-100 shadow ring-0 transition duration-200 ease-in-out']">
              <span :class="[isDark ? 'opacity-0 duration-100 ease-out bg-blue-300 rounded-full' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                <Icon icon="heroicons:sun-solid" class="w-5 h-5 text-yellow-600" />
              </span>
              <span :class="[isDark ? 'opacity-100 bg-slate-700 rounded-full duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                <Icon icon="heroicons:moon-solid" class="w-5 h-5 text-slate-100" />
              </span>
            </span>
          </Switch>
        </Box>
      </Box>
    </Box>

    <!-- Dynamic component injection -->
    <component :is="playgroundToDisplay"></component>
  </Page>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Page, Box } from '@/components/Core/Layout'
import ListboxPlayground from './ListboxPlayground.vue'
import ButtonPlayground from './ButtonPlayground.vue'
import TextPlayground from './TextPlayground.vue'
import { useDark, useToggle } from '@vueuse/core'
import { Switch } from '@headlessui/vue'
import { Icon } from '@iconify/vue'
import CascadeSelect2 from '@/components/Core/Form/CascadeSelect.vue'

const isDark = useDark()
const toggleDark = useToggle(isDark)

const currentPlayground = ref('Listbox')

/**
 * With Cascade select, it expects the options to be in an Array of Objects format
 * Each object must have a [name] key value which is the grouping section
 * As well as an components Array.
*/
const playgrounds = ref([
  {
    name: 'Form',
    components: [
      { label: 'Listbox', value: 'Listbox', component: ListboxPlayground }
    ]
  },
  // {
  //   name: 'Layout',
  //   components: []
  // },
  {
    name: 'General',
    components: [
      { label: 'Button', value: 'Button', component: ButtonPlayground },
      { label: 'Text', value: 'Text', component: TextPlayground }
    ]
  },
])

/**
 * Because the Options array is objects with nestest Array of components. We have to do two loops
 * through in order to get the component from each object in the Components array
*/
const playgroundToDisplay = computed(() => {
  let playground = null

  for (const section of playgrounds.value) {
    for (const component of section.components) {
      if (component.value === currentPlayground.value) {
        playground = component.component
      }
    }
  }

  return playground
})
</script>