<template>
  <component
    :is="`h${level}`"
    :class="[
      classList,
      textColor,
      center ? 'text-center' : null,
      // Makes Box w-full
      full ? 'w-full' : null,
       // Full padding
      {
        'p-1': padding === 'xxsmall',
        'p-2': padding === 'xsmall',
        'p-3': padding === 'small',
        'p-4': padding === 'medium',
        'p-6': padding === 'large',
        'p-8': padding === 'xlarge',
        'p-10': padding === 'xxlarge',
        'p-5': padding === 'gutter'
      },
      // Padding Left + Padding Right
      {
        'px-1': paddingX === 'xxsmall',
        'px-2': paddingX === 'xsmall',
        'px-3': paddingX === 'small',
        'px-4': paddingX === 'medium',
        'px-6': paddingX === 'large',
        'px-8': paddingX === 'xlarge',
        'px-10': paddingX === 'xxlarge',
        'px-5': paddingX === 'gutter'
      },
      // Padding Top + Padding Bottom
      {
        'py-1': paddingY === 'xxsmall',
        'py-2': paddingY === 'xsmall',
        'py-3': paddingY === 'small',
        'py-4': paddingY === 'medium',
        'py-6': paddingY === 'large',
        'py-8': paddingY === 'xlarge',
        'py-10': paddingY === 'xxlarge',
        'py-5': paddingY === 'gutter'
      },
      // Left Padding
      {
        'pl-1': paddingLeft === 'xxsmall',
        'pl-2': paddingLeft === 'xsmall',
        'pl-3': paddingLeft === 'small',
        'pl-4': paddingLeft === 'medium',
        'pl-6': paddingLeft === 'large',
        'pl-8': paddingLeft === 'xlarge',
        'pl-10': paddingLeft === 'xxlarge',
        'pl-5': paddingLeft === 'gutter'
      },
      // Right Padding
      {
        'pr-1': paddingRight === 'xxsmall',
        'pr-2': paddingRight === 'xsmall',
        'pr-3': paddingRight === 'small',
        'pr-4': paddingRight === 'medium',
        'pr-6': paddingRight === 'large',
        'pr-8': paddingRight === 'xlarge',
        'pr-10': paddingRight === 'xxlarge',
        'pr-5': paddingRight === 'gutter'
      },
      // Top Padding
      {
        'pt-1': paddingTop === 'xxsmall',
        'pt-2': paddingTop === 'xsmall',
        'pt-3': paddingTop === 'small',
        'pt-4': paddingTop === 'medium',
        'pt-6': paddingTop === 'large',
        'pt-8': paddingTop === 'xlarge',
        'pt-10': paddingTop === 'xxlarge',
        'pt-5': paddingTop === 'gutter'
      },
      // Bottom Padding
      {
        'pb-1': paddingBottom === 'xxsmall',
        'pb-2': paddingBottom === 'xsmall',
        'pb-3': paddingBottom === 'small',
        'pb-4': paddingBottom === 'medium',
        'pb-6': paddingBottom === 'large',
        'pb-8': paddingBottom === 'xlarge',
        'pb-10': paddingBottom === 'xxlarge',
        'pb-5': paddingBottom === 'gutter'
      },
      // Full Margin
      {
        'm-1': margin === 'xxsmall',
        'm-2': margin === 'xsmall',
        'm-3': margin === 'small',
        'm-4': margin === 'medium',
        'm-6': margin === 'large',
        'm-8': margin === 'xlarge',
        'm-10': margin === 'xxlarge',
        'm-5': margin === 'gutter'
      },
      // Margin Left + Margin Right
      {
        'mx-1': marginX === 'xxsmall',
        'mx-2': marginX === 'xsmall',
        'mx-3': marginX === 'small',
        'mx-4': marginX === 'medium',
        'mx-6': marginX === 'large',
        'mx-8': marginX === 'xlarge',
        'mx-10': marginX === 'xxlarge',
        'mx-5': marginX === 'gutter'
      },
      // Margin Top + Margin Bottom
      {
        'my-1': marginY === 'xxsmall',
        'my-2': marginY === 'xsmall',
        'my-3': marginY === 'small',
        'my-4': marginY === 'medium',
        'my-6': marginY === 'large',
        'my-8': marginY === 'xlarge',
        'my-10': marginY === 'xxlarge',
        'my-5': marginY === 'gutter'
      },
      // Left Margin
      {
        'ml-1': marginLeft === 'xxsmall',
        'ml-2': marginLeft === 'xsmall',
        'ml-3': marginLeft === 'small',
        'ml-4': marginLeft === 'medium',
        'ml-6': marginLeft === 'large',
        'ml-8': marginLeft === 'xlarge',
        'ml-10': marginLeft === 'xxlarge',
        'ml-5': marginLeft === 'gutter'
      },
      // Right Margin
      {
        'mr-1': marginRight === 'xxsmall',
        'mr-2': marginRight === 'xsmall',
        'mr-3': marginRight === 'small',
        'mr-4': marginRight === 'medium',
        'mr-6': marginRight === 'large',
        'mr-8': marginRight === 'xlarge',
        'mr-10': marginRight === 'xxlarge',
        'mr-5': marginRight === 'gutter'
      },
      // Top Margin
      {
        'mt-1': marginTop === 'xxsmall',
        'mt-2': marginTop === 'xsmall',
        'mt-3': marginTop === 'small',
        'mt-4': marginTop === 'medium',
        'mt-6': marginTop === 'large',
        'mt-8': marginTop === 'xlarge',
        'mt-10': marginTop === 'xxlarge',
        'mt-5': marginTop === 'gutter'
      },
      // Bottom Margin
      {
        'mb-1': marginBottom === 'xxsmall',
        'mb-2': marginBottom === 'xsmall',
        'mb-3': marginBottom === 'small',
        'mb-4': marginBottom === 'medium',
        'mb-6': marginBottom === 'large',
        'mb-8': marginBottom === 'xlarge',
        'mb-10': marginBottom === 'xxlarge',
        'mb-5': marginBottom === 'gutter'
      }
    ]">
    <slot />
  </component>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  center: {
    type: Boolean
  },
  color: {
    type: String,
    validator: (val) => ['amber', 'dark', 'default', 'green', 'light', 'purple', 'red', 'white'].includes(val),
    default: 'default'
  },
  full: {
    type: Boolean
  },
  level: {
    type: Number,
    validator: val => [1, 2, 3].includes(val),
    default: 1
  },
  margin: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginX: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginY: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginLeft: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginRight: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginTop: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginBottom: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  padding: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingX: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingY: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingLeft: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingRight: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingTop: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingBottom: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  }
})

const classList = computed(() => {
  const list = ['text-left', 'text-charcoal-900', 'dark:text-charcoal-100']

  list.push({
    1: 'text-4xl font-light',
    2: 'text-2xl font-semibold',
    3: 'text-lg font-medium'
  }[props.level])

  return list
})

const textColor = computed(() => {
  return {
    'amber': 'text-amber-600 dark:text-amber-300',
    'dark': 'text-charcoal-900 dark:text-charcoal-100', // More intense aka text higher contrast
    'default': 'text-charcoal-800 dark:text-charcoal-200',
    'green': 'text-green-600 dark:text-green-500',
    'light': 'text-charcoal-600 dark:text-charcoal-400', // Less intense aka text lower contrast
    'purple': 'text-iris-600 dark:text-iris-300',
    'red': 'text-red-600 dark:text-red-400',
    'white': 'text-white'
  }[props.color]
})
</script>